import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
  justify-content: center;
`

const Image = styled(Img)<{ fluid: any }>`
  margin: 0 auto;
  @media (min-width: 1000px) {
    max-width: 900px;
  }
  @media (max-width: 999px) {
    max-width: 400px;
  }
`

export default function Banner() {
  const { mobileImage, desktopImage } = useStaticQuery(query)

  return (
    <Wrapper>
      <a href="https://naszepierwszedziecko.pl/kurs?source=npd_index">
        <Image fluid={desktopImage.childImageSharp.fluid} />
      </a>
    </Wrapper>
  )
}

export const query = graphql`
  query {
    desktopImage: file(relativePath: { eq: "baner_kurs_wide.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
