import React, { Component } from "react";
import styled from "styled-components";

interface Props {
  title: string;
}

const Wrapper = styled.div`
  text-align: left;
`;

const Header = styled.h2`
  color: rgb(0, 171, 107) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
  margin-bottom: 27px;
`;

const HeaderText = styled.span`
  border-bottom: 1px solid rgba(0, 0, 0, 0.44) !important;
  display: inline-block;
  padding-bottom: 20px;
  margin-bottom: -1px;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
`;

class CategoryLabel extends Component<Props> {
  render() {
    return (
      <Wrapper>
        <Header>
            <HeaderText className={'ml-2'}>{this.props.title}</HeaderText>
        </Header>
      </Wrapper>
    );
  }
}
export default CategoryLabel;