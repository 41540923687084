import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/main.scss"
import styled from "styled-components"
import Img from "gatsby-image"

const SpacedNavbar = styled.nav`
  padding-bottom: 0 !important;
`

const NavbarItem = styled.a`
  @media (min-width: 1040px) {
    padding: 0.5rem 0.3rem;
  }
`

const CategoryLabel = styled.div`
  margin-bottom: 0.3rem !important;
`

interface Category {
  id: string
  title: string
  slug: string
}

export default function Nav() {
  const [menuActive, setMenuActive] = useState(false)

  const { allCategory: categories, logo } = useStaticQuery(graphql`
    query CategoriesQuery {
      allCategory {
        nodes {
          id
          title
          slug
        }
      }
      logo: file(relativePath: { eq: "logo-250-80.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <SpacedNavbar
      className="navbar is-transparent is-spaced"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <div className="navbar-item">
          <a href="/">
            <Img
              imgStyle={{ maxWidth: 200 }}
              fixed={logo.childImageSharp.fixed}
              alt={"Logo Nasze Pierwsze Dziecko"}
            />
          </a>
        </div>

        <a
          role="button"
          className={`navbar-burger burger ${menuActive && "is-active"}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar"
          onClick={() => setMenuActive(!menuActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbar"
        className={`navbar-menu ${menuActive ? "is-active" : ""}`}
        style={{ flexDirection: "column" }}
      >
        <div className="navbar-end">
          <a className="navbar-item" href="/kurs">
            Szkoła Rodzenia z Anną Nowak-Dudą
          </a>
          <a className="navbar-item" href="/o-projekcie">
            O projekcie
          </a>
          <a className="navbar-item" href="/blog">
            Blog
          </a>
        </div>
        <div className="navbar-end">
          <CategoryLabel className="navbar-item is-hidden-desktop subtitle is-4 ">
            Kategorie:{" "}
          </CategoryLabel>
          {categories.nodes
            .filter((category: Category) => category.title !== "Blog")
            .map((category: Category) => (
              <NavbarItem
                className="navbar-item"
                href={`/artykuly/${category.slug}`}
                key={category.id}
              >
                {category.title}
              </NavbarItem>
            ))}
        </div>
      </div>
    </SpacedNavbar>
  )
}
