import React from "react"
import "../../styles/main.scss"
import SEO from "../../components/seo"
import { Skeleton } from "../../components/layout/Skeleton"
import CategoryLabel from "../../components/CategoryLabel"
import styled from "styled-components"
import Section from "../../components/course/Section"

const AboutProjectContainer = styled(Section)`
  max-width: 780px;
`

const List = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
`

const Paragraph = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

const AboutProject = () => {
  return (
    <>
      <SEO
        title={`O projekcie "Nasze Pierwsze Dzieko"`}
        description={`Dowiedz się kto, po co i dlaczego tworzy projekt "Nasze Pierwsze Dziecko"`}
      />
      <Skeleton banner={false}>
        <AboutProjectContainer className={"container"}>
          <CategoryLabel title={"O projekcie"} />
          <Paragraph>
            "Nasze pierwsze dziecko" to projekt skierowany do przyszłych i
            aktualnych mam, którego celem jest dostarczenie solidnego i
            darmowego wsparcia dla przyszłych i nowych rodziców z wykorzystaniem
            nowych technologii i sposobów korzystania z treści.
          </Paragraph>
          <h3 className={"title is-4"}>Na projekt w tej chwili składają się</h3>
          <List>
            <li>
              Aplikacja <a href="https://asystentciazy.pl">Asystent Ciąży</a>
            </li>
            <li>
              Serwis internetowy z artykułami skierowanymi do kobiet w ciąży -{" "}
              <a href="/">https://naszepierwszedziecko.pl</a>
            </li>
            <li>
              Kurs Szkoły Rodzenia -{" "}
              <a href="/szkola-rodzenia-z-anna-nowak-duda/">
                https://naszepierwszedziecko.pl/szkola-rodzenia-z-anna-nowak-duda/
              </a>
            </li>
          </List>
          <Paragraph>
            Ciągle rozwijamy aktualne (i pracujemy nad nowymi) produkty.
          </Paragraph>
          <Paragraph>
            Pomysłodawczynią i liderką projektu jest Joanna Mikołajczuk.
          </Paragraph>
          <div className="columns">
            <div className="column is-one-fifth has-text-centered">
              <figure className="image is-128x128">
                <img
                  className="is-rounded"
                  src="/joanna-mikolajczuk.jpg"
                  alt="Joanna Mikołajczuk"
                />
              </figure>
            </div>
            <div className="column">
              <div className="subtitle ">Joanna Mikołajczuk</div>
              <span className="author-description">
                Asia od kilku lat pracuje w branży wydawniczej (wiadomości
                lokalne) i świetnie potrafi prowadzić oraz rozwijać rożne
                społeczności w sieci. Od listopada 2018 jest również mamą
                słodkiego Tymona!
              </span>
            </div>
          </div>
          <Paragraph>
            Miesięcznie z naszych aplikacji i serwisu korzysta prawie 10 tys.
            kobiet (liczba ta dynamicznie rośnie).
          </Paragraph>
          <Paragraph>
            Projekt nie oferuje pomocy zastępującej lekarzy.
          </Paragraph>
        </AboutProjectContainer>
      </Skeleton>
    </>
  )
}

export default AboutProject
