import React from "react"
import "../../styles/main.scss"
import Nav from "../../components/nav"
import { Container } from "./container"
import Footer from "../../components/layout/Footer"
import Banner from "../../components/course/banner"

export const Skeleton: React.FunctionComponent<{ banner?: boolean }> = ({
  banner = true,
  children,
}) => {
  return (
    <>
      <Container>
        <Nav />
      </Container>
      <hr />
      <Container>
        {banner && <Banner />}
        {children}
      </Container>
      <Footer />
    </>
  )
}
