import React from "react"
import styled from "styled-components"

const Container = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.44);
  margin-top: 50px;
  margin-bottom: 50px;

  & a {
    color: #00ab6b;
  }

  & p {
    @media (min-width: 540px) {
      margin-bottom: 40px;
    }
  }
`

const Ul = styled.ul`
  list-style: disc;
  padding-left: 20px;
`

const FooterLinks = ({ className }: { className: string }) => {
  return (
    <>
      {className === "is-hidden-tablet" && <p></p>}
      <p
        className={`${
          className === "is-hidden-tablet" ? "" : "has-text-right"
        } ${className}`}
        style={{ marginTop: className === "is-hidden-tablet" ? "15px" : 0 }}
      >
        <a href="/redakcja">Redakcja</a> |{" "}
        <a href="/o-projekcie">O projekcie</a> |{" "}
        <a href="https://s3.eu-central-1.amazonaws.com/pl.naszepierwszedziecko.publisher/polityka-prywatnos%CC%81ci-naszepierwszedziecko-15-10.pdf">
          Polityka prywatności
        </a>{" "}
        |{" "}
        <a href="https://s3.eu-central-1.amazonaws.com/pl.naszepierwszedziecko.publisher/regulamin-sklepu-21.01.2020.pdf">
          Regulamin sklepu
        </a>{" "}
        | <a href="/blog">Blog</a>
      </p>
    </>
  )
}

export default function Footer() {
  return (
    <Container>
      <div className="columns">
        <div className="column is-3">
          <p>Nasze Pierwsze Dziecko {new Date().getFullYear()}</p>
          <h5 className="subtitle is-5">Aplikacje</h5>
          <Ul>
            <li>
              <a href="https://asystentciazy.pl/">Asystent Ciąży</a>
            </li>
          </Ul>
        </div>
        <div className="column is-3">
          <p>&nbsp;</p>
          <h5 className="subtitle is-5">Materiały</h5>
          <Ul>
            <li>
              <a href="/materialy/lista-wyprawkowa">Listy Wyprawkowe</a>
            </li>
            <li>
              <a href="/materialy/wybor-szpitala-do-porodu">
                Kryteria wyboru szpitala do porodu
              </a>
            </li>
          </Ul>
        </div>
        <div className="column is-6">
          <FooterLinks className={"is-hidden-mobile"} />
          <h5 className="subtitle is-5">Kursy</h5>
          <Ul>
            <li>
              <a href="/kurs">Szkoła Rodzenia z Anną Nowak-Dudą</a>
            </li>
          </Ul>
          <FooterLinks className={"is-hidden-tablet"} />
        </div>
      </div>
    </Container>
  )
}
